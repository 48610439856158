import React from "react";
import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  Content6Steps,
  ExternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../components";
import { Row, Col } from "antd";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Colors } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPencilRuler,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      HousesDrone: file(relativePath: { eq: "other/houses_drone.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Family1: file(relativePath: { eq: "other/family1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Family2: file(relativePath: { eq: "other/family2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Architect: file(relativePath: { eq: "other/architect.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Team1: file(relativePath: { eq: "other/team1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Brands: file(relativePath: { eq: "banner/gg_banner.png" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Caesarstone: file(relativePath: { eq: "suppliers/caesarstone.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Laminex: file(relativePath: { eq: "suppliers/laminex.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const FluidImg = ({ name, style }) => (
    <Img style={{ ...style }} fluid={data[name].childImageSharp.fluid} />
  );
  const Title = ({ title }) => (
    <h1 style={{ fontWeight: "400", color: Colors.DarkGreen }}>{title}</h1>
  );
  return (
    <Layout>
      <SEO
        title="About Us"
        description={"Leading custom home builder in Victoria"}
      />
      <BannerPage
        title={"About Us"}
        subTitle={"Leading custom home builder in Victoria"}
      />
      <ContainerContent
        style={{
          background: Colors.DarkGray,
        }}
      >
        <h2 style={{ textAlign: "center", color: Colors.White }}>OUR BRANDS</h2>
        <Img
          style={{ margin: "auto", maxWidth: "800px", left: "28px" }}
          fluid={data["Brands"].childImageSharp.fluid}
        />
      </ContainerContent>
      <ContainerContentFixed>
        <Row>
          <Col md={8} xs={24}>
            <div style={{ padding: "2rem" }}>
              <Title title="About Grandeur Homes" />

              <div style={{ fontSize: "1.1rem" }}>
                <p>
                  The Grandeur Homes team is the leader in custom built homes
                  that suit the owners down to the ground. From design and plans
                  to the finished Product, we work with you to bring everything
                  you want in a home to reality.
                </p>
                <p>
                  No Nasty surprises or compromises. We remember the most
                  important thing -{" "}
                  <b style={{ color: Colors.DarkGreen }}>
                    seeing you in the home you love.
                  </b>
                </p>
              </div>
            </div>
          </Col>
          <Col md={16} xs={24}>
            <FluidImg name={"Family1"} />
          </Col>
        </Row>
      </ContainerContentFixed>{" "}
      <ContainerContent
        style={{ backgroundColor: Colors.LightGray, textAlign: "center" }}
      >
        <ContainerContentFixed>
          <Title title="Why Us" />

          <div style={{ fontSize: "1.1rem" }}>
            <p>
              We know it is not about us. It’s about you and what you want.
              That’s why we are dedicated to building you a quality home that’s
              designed to your style and taste. No wonder we continue to grow in
              reputation as a true quality builder. Our goal is to always stay
              true to the best workmanship and the best possible service.
            </p>
            {[
              { text: "Quality", icon: faHome },
              { text: "Design", icon: faPencilRuler },
              { text: "Commitment", icon: faHandshake },
            ].map((item, i) => (
              <div
                style={{
                  display: "inline-block",
                  margin: "8px 8px",
                  textAlign: "center",
                  minWidth: "100px",
                }}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{ color: Colors.DarkGreen, fontSize: "2rem" }}
                />
                <h4 key={`i${i}`}>{item.text} </h4>
              </div>
            ))}
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerContentFixed>
        <Row>
          <Col md={16} xs={24}>
            <FluidImg name={"Team1"} />
          </Col>
          <Col md={8} xs={24}>
            <div style={{ padding: "2rem" }}>
              <Title title="Our Vision" />
              <div style={{ fontSize: "1.1rem" }}>
                <p>
                  We have a special vision - making sure you see your dream home
                  come to life.
                </p>
                <p>
                  No-one can build a dream home unless they build a great
                  relationship.
                </p>
                <p>
                  <b style={{ color: Colors.DarkGreen }}>
                    That's the Grandeur Difference.
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </ContainerContentFixed>
      <section>
        <ContainerContent
          style={{ backgroundColor: Colors.LightGray, textAlign: "center" }}
        >
          <ContainerContentFixed>
            <Content6Steps />
          </ContainerContentFixed>
        </ContainerContent>
      </section>
      <ContainerContentFixed>
        <Row>
          <Col md={8} xs={24}>
            <div style={{ padding: "2rem" }}>
              <Title title="A Special Home, Custom built for you" />
              <div style={{ fontSize: "1.1rem" }}>
                <p>
                  We’re always open to your ideas. We’re all about building a
                  home you will love.
                </p>
                <p>
                  There’s no need to keep dreaming of your dream home. Wake up
                  in the place you have always wanted with help from the{" "}
                  <b style={{ color: Colors.DarkGreen }}>
                    team at Grandeur Homes
                  </b>
                  .
                </p>
              </div>
            </div>
          </Col>
          <Col md={16} xs={24}>
            <FluidImg name={"Family2"} />
          </Col>
        </Row>
      </ContainerContentFixed>
      <section>
        <ContainerContent
          style={{ backgroundColor: Colors.LightGray, textAlign: "center" }}
        >
          <ContainerContentFixed>
            <Title title={"Our Suppliers"} />
            <div style={{ fontSize: "1.1rem" }}>
              <p>We use the best quality products from the best suppliers.</p>
            </div>
            <div style={{ background: Colors.White, padding: "2rem" }}>
              <Row gutter={[16, 16]}>
                <Col md={6} offset={6}>
                  <br />
                  <ExternalLink url={"https://www.caesarstone.com.au/"}>
                    <FluidImg name={"Caesarstone"} />
                  </ExternalLink>
                </Col>
                <Col md={6}>
                  <ExternalLink url={"https://www.laminex.com.au/"}>
                    <FluidImg name={"Laminex"} />
                  </ExternalLink>
                </Col>
              </Row>
            </div>
          </ContainerContentFixed>
        </ContainerContent>
      </section>
      <ContainerCta
        title={"If you can dream it, we can build it"}
        subTitle={
          "Bring your ideas, and together we will build your next dream home"
        }
        secondaryText={"Contact Us"}
        secondaryLink={InternalLinkTypes.Contact}
      />
    </Layout>
  );
};

export default About;
